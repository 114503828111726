import * as React from "react"
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"

const NotFound = () => (
  <Layout>
    <h1>404 - Not found</h1>
    <p>perhaps that exists in another universe :(</p>
  </Layout>
)

export default NotFound
